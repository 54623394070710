<template>
  <div class="preview-box add-package">
    <div class="preview-box-container">
      <div class="preview-box-action-container">
          <div class="preview-box-tools">
            <PackageConfirmView
              :actionButtonText="action"
              :title="action"
              :action="actionAddPackage"
              @on-action-done="onActionDone"
            >
              <template v-slot:confirm>
                <div v-if="actionAddPackage.key">
                  <label>Key</label>
                  <p class="text">{{actionAddPackage.key}}</p>
                </div>
                <div>
                  <label>Type</label>
                  <select v-model="actionAddPackage.type" placeholder="Choose a package">
                    <option value="" disabled selected>Choose a package</option>
                    <template v-for="type in PackageTypes">
                      <option v-if="type.key" :key="type" :value="type.value.key" >{{type.value.name}}</option>
                    </template>
                  </select>
                </div>
                <template v-if="actionAddPackage.type">
                  <div>
                    <label>Package Name</label>
                    <input type="text" v-model="actionAddPackage.name"/>
                  </div>
                  <div>
                    <label>Index</label>
                    <input type="number" v-model="actionAddPackage.index"/>
                  </div>
                  <div v-if="actionAddPackage.type === PackageTypes._values.FreePlan.key ||
                  actionAddPackage.type === PackageTypes._values.AssociationPackage.key">
                    <label>Days of access</label>
                    <input type="number" v-model="actionAddPackage.days_of_access"/>
                  </div>
                  <div>
                    <label>Show in website</label>
                    <select v-model="actionAddPackage.show_in_website">
                      <option value="" disabled selected>Select</option>
                      <option :value="true">Yes</option>
                      <option :value="false">No</option>
                    </select>
                  </div>
                  <div>
                    <label>Show resource page</label>
                    <select v-model="actionAddPackage.show_resource_page">
                      <option value="" disabled selected>Select</option>
                      <option :value="true">Yes</option>
                      <option :value="false">No</option>
                    </select>
                  </div>
                  <div>
                    <label>Active</label>
                    <select v-model="actionAddPackage.active">
                      <option value="" disabled selected>Select</option>
                      <option :value="true">Yes</option>
                      <option :value="false">No</option>
                    </select>
                  </div>
                  <div>
                    <label>Ants Product</label>
                    <select v-model="actionAddPackage.is_ants_product">
                      <option value="" disabled selected>Select</option>
                      <option :value="true">Yes</option>
                      <option :value="false">No</option>
                    </select>
                  </div>
                  <div>
                    <label>Access Type</label>
                    <select v-model="actionAddPackage.access_type">
                      <option value="" disabled selected>Select</option>
                      <option :value="'limited_access'">Limited Access</option>
                      <option :value="'no_restriction'">No Restriction</option>
                    </select>
                  </div>
                  <div>
                    <label>Unit label</label>
                    <input
                      type="text"
                      v-model="actionAddPackage.unit_label"
                    />
                  </div>
                  <div>
                    <label>Show early bird price</label>
                    <select v-model="actionAddPackage.show_early_bird_pricing">
                      <option value="" disabled selected>Select</option>
                      <option :value="true">Yes</option>
                      <option :value="false">No</option>
                    </select>
                  </div>
                  <div>
                    <label>Early bird pricing notes</label>
                    <textarea
                      rows="6"
                      v-model="actionAddPackage.early_bird_pricing_notes"
                    />
                  </div>
                  <div>
                    <label>Resource Key</label>
                    <textarea
                      rows="6"
                      v-model="actionAddPackage.resource_key"
                    />
                  </div>
                  <div>
                    <label>Allowed Endpoints</label>
                    <textarea
                      rows="6"
                      v-model="actionAddPackage.allowed_endpoints"
                    />
                  </div>
                  <div>
                    <label>Subtitle</label>
                    <textarea
                      rows="6"
                      v-model="actionAddPackage.subtitle"
                    />
                  </div>
                  <div>
                    <label>Description</label>
                    <textarea
                      rows="6"
                      v-model="actionAddPackage.description"
                    />
                  </div>
                  <div>
                    <h2>Price</h2>
                    <div>
                      <label>USD<span>*</span></label>
                      <input type="number" v-model="actionAddPackage.minAmountUsd"/>
                    </div>
                    <div>
                      <label>EUR<span>*</span></label>
                      <input type="number" v-model="actionAddPackage.minAmountEur"/>
                    </div>
                    <div>
                      <label>INR<span>*</span></label>
                      <input type="number" v-model="actionAddPackage.minAmountInr"/>
                    </div>
                  </div>
                  <div v-if="actionAddPackage.type === PackageTypes._values.StandardPackage.key">
                    <h2>Discount Percentage</h2>
                    <div>
                      <label>Half yearly discount %</label>
                      <input type="number" v-model="actionAddPackage.half_yearly_discount"/>
                    </div>
                    <div>
                      <label>Annual yearly discount %</label>
                      <input type="number" v-model="actionAddPackage.annual_discount"/>
                    </div>
                  </div>
                  <div>
                    <h2>Access Limits</h2>
                    <template v-if="actionAddPackage.type === PackageTypes._values.StandardPackage.key">
                      <div>
                          <label>Historical access in weeks</label>
                          <input type="number" v-model="actionAddPackage.usage_limit_historical_access"/>
                      </div>
                      <div>
                          <label>Matches</label>
                          <input type="number" v-model="actionAddPackage.usage_limit_matches"/>
                      </div>
                    </template>
                    <div>
                      <label>Hits</label>
                      <input type="number" v-model="actionAddPackage.usage_limit_hits"/>
                    </div>
                    <div>
                      <label>Period</label>
                      <select v-model="actionAddPackage.usage_limit_period" placeholder="Usage limit period">
                        <option value="" disabled selected>Usage limit period</option>
                        <template v-for="type in PackageLimitTypes">
                          <option v-if="type.key" :key="type" :value="type.value.key" >{{type.value.name}}</option>
                        </template>
                      </select>
                    </div>
                  </div>
                </template>
              </template>
            </PackageConfirmView>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ref,
  toRef,
  watch,
  onMounted,
} from 'vue';
import {
  ProjectList, AdminAction, PackageTypes, PackageLimitTypes,
} from '@/store/modules/admin';
import moment from 'moment';

import PackageConfirmView from '@/components/admin/PackageConfirmView.vue';

export const datePickerFormat = (date) => moment.unix(date).format('DD/MM/YYYY');

export default {
  props: {
    action: String,
    packageKey: String,
    response: Object,
  },

  components: {
    PackageConfirmView,
  },

  setup(props) {
    const action = toRef(props, 'action');
    let endpointsArray = [];
    let actionAddPackage;

    if (action.value !== 'edit') {
      actionAddPackage = ref(
        new AdminAction(() => ProjectList.addPackages({
          price: {
            unit_amount: {
              usd: parseInt(actionAddPackage.value.minAmountUsd, 10),
              eur: parseInt(actionAddPackage.value.minAmountEur, 10),
              inr: parseInt(actionAddPackage.value.minAmountInr, 10),
            },
            volume_discounts_price_variation: actionAddPackage.value.type === 'standard_package' ? [
              {
                min_quantity: 6,
                discount_percentage: actionAddPackage.value.half_yearly_discount || '',
                license_plan: 'package',
              },
              {
                min_quantity: 12,
                discount_percentage: actionAddPackage.value.annual_discount || '',
                license_plan: 'package',
              },
            ] : [],
            unit_label: actionAddPackage.value.unit_label,
          },
          rights_metadata: {
            hits_limit: parseInt(actionAddPackage.value.usage_limit_hits, 10) || 0,
            matches_limit: parseInt(actionAddPackage.value.usage_limit_matches, 10) || 0,
            limit_period: actionAddPackage.value.usage_limit_period || 'NA',
            resource_access_time_limit_weeks: parseInt(actionAddPackage.value.usage_limit_historical_access, 10) || -1,
          },
          access_type: actionAddPackage.value.access_type,
          name: actionAddPackage.value.name,
          resource_key: actionAddPackage.value.resource_key,
          show_early_bird_pricing: actionAddPackage.value.show_early_bird_pricing,
          show_in_website: actionAddPackage.value.show_in_website,
          show_resource_page: actionAddPackage.value.show_resource_page,
          early_bird_pricing_notes: actionAddPackage.value.early_bird_pricing_notes,
          index: actionAddPackage.value.index,
          active: actionAddPackage.value.active,
          allowed_endpoints: endpointsArray,
          description: actionAddPackage.value.description,
          subtitle: actionAddPackage.value.subtitle,
          type: actionAddPackage.value.type,
          key: actionAddPackage.value.key,
          is_ants_product: actionAddPackage.value.is_ants_product,
          days_of_access: parseInt(actionAddPackage.value.days_of_access, 10),
          action,
        })),
      );
    } else {
      actionAddPackage = ref(
        new AdminAction(() => ProjectList.editPackage({
          price: {
            unit_amount: {
              usd: parseInt(actionAddPackage.value.minAmountUsd, 10),
              eur: parseInt(actionAddPackage.value.minAmountEur, 10),
              inr: parseInt(actionAddPackage.value.minAmountInr, 10),
            },
            unit_label: actionAddPackage.value.unit_label,
            volume_discounts_price_variation: actionAddPackage.value.type === 'standard_package' ? [
              {
                min_quantity: 6,
                discount_percentage: actionAddPackage.value.half_yearly_discount || '',
                license_plan: 'package',
              },
              {
                min_quantity: 12,
                discount_percentage: actionAddPackage.value.annual_discount || '',
                license_plan: 'package',
              },
            ] : [],
          },
          rights_metadata: {
            hits_limit: parseInt(actionAddPackage.value.usage_limit_hits, 10) || 0,
            matches_limit: parseInt(actionAddPackage.value.usage_limit_matches, 10) || 0,
            limit_period: actionAddPackage.value.usage_limit_period,
            resource_access_time_limit_weeks: parseInt(actionAddPackage.value.usage_limit_historical_access, 10) || -1,
          },
          access_type: actionAddPackage.value.access_type,
          name: actionAddPackage.value.name,
          resource_key: actionAddPackage.value.resource_key,
          show_early_bird_pricing: actionAddPackage.value.show_early_bird_pricing,
          show_in_website: actionAddPackage.value.show_in_website,
          show_resource_page: actionAddPackage.value.show_resource_page,
          early_bird_pricing_notes: actionAddPackage.value.early_bird_pricing_notes,
          index: actionAddPackage.value.index,
          active: actionAddPackage.value.active,
          allowed_endpoints: endpointsArray,
          description: actionAddPackage.value.description,
          subtitle: actionAddPackage.value.subtitle,
          type: actionAddPackage.value.type,
          key: actionAddPackage.value.key,
          is_ants_product: actionAddPackage.value.is_ants_product,
          days_of_access: parseInt(actionAddPackage.value.days_of_access, 10),
          action,
        })),
      );
    }
    watch(() => actionAddPackage.value.allowed_endpoints, () => {
      if (actionAddPackage && actionAddPackage.value && actionAddPackage.value.allowed_endpoints !== null) {
        if (!Array.isArray(actionAddPackage.value.allowed_endpoints)) {
          endpointsArray = actionAddPackage.value.allowed_endpoints.split(',').filter((val) => val.trim() !== '');
        } else {
          endpointsArray = actionAddPackage.value.allowed_endpoints;
        }
      }
    });
    const fetchData = () => {
      if (props.response && props.action === 'edit' && props.packageKey) {
        const packageInfo = props.response.find((x) => x.key === props.packageKey);
        actionAddPackage.value.name = packageInfo.name || '';
        actionAddPackage.value.resource_key = packageInfo.resource_key || '';
        actionAddPackage.value.minAmountUsd = packageInfo.price.unit_amount.usd || '';
        actionAddPackage.value.minAmountEur = packageInfo.price.unit_amount.eur || '';
        actionAddPackage.value.minAmountInr = packageInfo.price.unit_amount.inr || '';
        actionAddPackage.value.description = packageInfo.description || '';
        actionAddPackage.value.subtitle = packageInfo.subtitle || '';
        actionAddPackage.value.show_early_bird_pricing = packageInfo.show_early_bird_pricing;
        actionAddPackage.value.show_in_website = packageInfo.show_in_website;
        actionAddPackage.value.show_resource_page = packageInfo.show_resource_page;
        actionAddPackage.value.active = packageInfo.active;
        actionAddPackage.value.early_bird_pricing_notes = packageInfo.early_bird_pricing_notes || null;
        actionAddPackage.value.index = packageInfo.index >= 0 ? packageInfo.index : null;
        actionAddPackage.value.allowed_endpoints = packageInfo.allowed_endpoints || [];
        actionAddPackage.value.type = packageInfo.type;
        actionAddPackage.value.unit_label = packageInfo.price.unit_label;
        actionAddPackage.value.key = packageInfo.key || '';
        actionAddPackage.value.access_type = packageInfo.access_type;
        actionAddPackage.value.half_yearly_discount = packageInfo?.price?.volume_discounts_price_variation?.[
          0]?.discount_percentage || '';
        actionAddPackage.value.annual_discount = packageInfo?.price?.volume_discounts_price_variation?.[
          1]?.discount_percentage || '';
        actionAddPackage.value.days_of_access = packageInfo.days_of_access;
        actionAddPackage.value.is_ants_product = packageInfo.is_ants_product;
        actionAddPackage.value.usage_limit_hits = packageInfo?.rights_metadata?.hits_limit || 0;
        actionAddPackage.value.usage_limit_matches = packageInfo?.rights_metadata?.matches_limit || 0;
        actionAddPackage.value.usage_limit_period = packageInfo?.rights_metadata?.limit_period || 'NA';
        actionAddPackage.value.usage_limit_historical_access = packageInfo?.rights_metadata
          ?.resource_access_time_limit_weeks || -1;
      }
    };
    onMounted(() => {
      fetchData();
    });

    const resetForm = {
      resource_key: '',
      name: '',
      price: '',
      description: '',
      show_early_bird_pricing: '',
      show_in_website: '',
      show_resource_page: '',
      allowed_endpoints: [],
      active: '',
      type: '',
      key: '',
      index: null,
      notes: '',
      unit_label: '',
      access_type: '',
      subtitle: '',
      is_ants_product: '',
      rights_metadata: '',
    };

    const onActionDone = () => {
      Object.assign(actionAddPackage.value, resetForm);
    };

    return {
      actionAddPackage,
      onActionDone,
      PackageTypes,
      PackageLimitTypes,
    };
  },
};
</script>
<style lang="scss">
.add-package {
  &.preview-box {
    .preview-box-tools {
      margin: 0 auto;
      padding: 0!important;
      .btn {
        &.btn-sm {
          margin-bottom: 0;
          margin-right: 0;
        }
      }
      .body {
        h2 {
          border-bottom: size(1) solid var(--global--supportive--Color);
          padding-bottom: size(10);
          margin: size(20) 0;
        }
        >div {
          label {
            margin-bottom: size(6);
            color: var(--global--supportive--Color);
            font-size: size(12);
            line-height: size(18);
          }
          select, input, textarea, p {
            display: block;
            width: 100%;
            border-radius: size(4);
            color: var(--global--white--Color);
            padding: size(6);
            font-size: size(14);
            line-height: size(20);
            background: none;
            outline: var(--global--supportive--Color);
            border: size(1) solid var(--global--supportive--Color);
          }
           select {
            border: 0;
            outline: size(1) solid var(--global--supportive--Color);
            border-right: size(10) solid transparent;
            appearance: none;
            background-image: url('../../assets/dropdown-white.svg');
            background-repeat: no-repeat;
            background-position: top size(5) right 0;

            option {
              color: var(--global--main--Color);
            }
          }

          input[type="date"] {
            &::-webkit-calendar-picker-indicator {
              filter: invert(1);
            }
          }
        }
      }
      p {
        &.text {
          display: inline-block;
          padding: 0;
          color: var(--global--white--Color);
          font-size: size(14);
          font-style: normal;
          margin-bottom: size(25);
        }
      }
      label {
        display: inline-block;
        vertical-align: top;
        margin-right: size(25);
        margin-top: 0;
        min-width: size(180);
        span {
          color: var(--global--error--BackgroundColor);
          padding-left: size(2);
        }
      }
      .tools {
        margin-top: size(25);
        text-align: right;
      }
      .error {
        padding: size(20) 0;
        color: var(--global--error--BackgroundColor);
        font-size: size(15);
      }
      .start-date, .end-date {
        display: inline-block;
        svg {
          width: size(12);
        }
      }
    }
  }
  .amount-container {
    span {
      padding-right: size(10);
    }
  }
}
</style>
